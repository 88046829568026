import { BaseAPI } from "@utils/base-api";
import { AxiosResponse } from "axios";
import { Product } from "libs/api/customer/src/lib/types/products";

export type VariablePricingKey = number | "default";

export interface IVariablePricing {
  discount: number;
  unitPrice: number;
  quantity: number;
}

export type IQuantityToPrice = {
  quantityToPrice: Record<VariablePricingKey, IVariablePricing>
};

export interface IUpcomingDiscount {
  name: string;
  expiresAt: string;
}

export class LitterAPI extends BaseAPI {

  public async getPricesByProductId(productTitle: Product):Promise<AxiosResponse<IQuantityToPrice>> {
    try {
      return await this.axios.get(
        `/customer/extraPlan/getProductPrices?productTitle=${productTitle}`,
      );
    } catch (error) {
      throw new Error();
    }
  }

  public async changeCadence(
    parentId: number,
    cadence: number,
    quantity: number | undefined,
  ): Promise<void> {
    try {
      return await this.axios.post(
        "/customer/extraPlan/changeCadence",
        {
          parentId,
          cadence,
          quantity,
        },
      );
    } catch (error) {
      throw new Error();
    }
  }

  public async changeQuantity(_parentId: number, _productTitle: string, _quantity: number): Promise<void> {
    try {
      return await this.axios.post(
        "/customer/changeAddonsList",
        { freshMealPlanId: _parentId, addons: { [_productTitle]: _quantity },
        },
      );
    } catch (error) {
      throw new Error();
    }
  }

}
