import { BaseAPI } from "@utils/base-api";
import { IDiscountApplicationCustomerInfo } from "@discounts/types";
import { AxiosResponse } from "axios";

import { IFreshSubDiscountCalculations } from "@/discount-calculation";
import { DiscountApplication } from "@/primary-models";

export interface IUpcomingDiscount {
  name: string;
  expiresAt: string;
}

export class DiscountsAPI extends BaseAPI {

  public async getQueuedDiscounts(): Promise<AxiosResponse<IDiscountApplicationCustomerInfo[]>> {
    return this.axios.get(
      "/applications/customer?filter=POTENTIALLY USABLE",
    );
  }

  public async getAllDiscountApplications(): Promise<AxiosResponse<IDiscountApplicationCustomerInfo[]>> {
    return this.axios.get(
      "/applications/customer",
    );
  }

  public async getDiscountCalculations(subscriptionId: number, discountCode: string):
  Promise<AxiosResponse<IFreshSubDiscountCalculations>> {
    return this.axios.get(
      `/estimation/subscription?subscriptionId=${subscriptionId}&code=${discountCode}`,
    );
  }

  public async applyDiscount(discountCode: string):
  Promise<AxiosResponse<DiscountApplication>> {
    return this.axios.post("/applications/customer", { code: discountCode });
  }

}
